
.icon-list[data-v-147fcbf0] {
  padding: 0;
  margin: 0;
  font-size: 0;
}
.icon-list li[data-v-147fcbf0] {
  width: 10%;
  height: 60px;
  font-size: var(--rootFontSize);
  text-align: center;
  list-style-type: none;
  overflow: hidden;
  padding: 15px 6px 6px 0px;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.icon-list li[data-v-147fcbf0]:hover {
  background-color: #f2f6fc;
  cursor: pointer;
}
.activeIcon[data-v-147fcbf0] {
  background-color: #f2f6fc;
  cursor: pointer;
}
.icon-list li i[data-v-147fcbf0] {
  width: 100%;
  font-size: var(--rootFontSize3);
  padding: 6px 6px 6px 0px;
}
.icon-name[data-v-147fcbf0] {
  display: block;
  padding-left: 6px;
}
.el-dialog[data-v-147fcbf0] {
  border-radius: 8px;
  margin-bottom: 0;
  margin-top: 4vh !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-height: 92vh;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.el-dialog .el-dialog__header[data-v-147fcbf0] {
  padding-top: 14px;
}
.el-dialog .el-dialog__body[data-v-147fcbf0] {
  overflow: auto;
}
